@import '~ethos-design-system/src/components/Media/Media.scss';
$laptop-range-start: 900px;

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  &.containsBackground {
    .heading p {
      color: white;
      font-family: Cambon;
      font-size: 36.5px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.766px;
    }

    @include for-laptop-and-up {
      font-size: 43.5px;
      line-height: 48px;
      letter-spacing: -0.609px;
      margin-bottom: 16px;
    }

    .subheading p {
      color: white;
      font-family: Theinhardt;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.02px;

      @include for-laptop-and-up {
        font-size: 19px;
        line-height: 24px;
        margin-bottom: 80px;
      }
    }
  }
}

.heading,
.subheading {
  max-width: 675px;
  text-align: center;
}

.video {
  width: 100%;
  @include for-desktop-only {
    width: 875px;
  }
  @include for-laptop-only {
    width: 612px;
  }
  @include for-tablet-only {
    width: 462px;
  }
}

.outerContainer {
  padding-top: var(--Space-48);
  position: relative;

  @include for-laptop-and-up {
    padding-top: var(--Space-80);
  }

  &.containsBackground {
    height: 597px;

    @include for-laptop-and-up {
      height: 957px;
    }
  }
}

.outerContainer {
  padding-top: var(--Space-48);
  position: relative;

  @include for-laptop-and-up {
    padding-top: var(--Space-80);
  }

  &.containsBackground {
    height: 597px;
    overflow: hidden;

    @include for-laptop-and-up {
      height: 957px;
    }
  }
}

.container {
  position: relative;
  z-index: 0;
  overflow: hidden;

  padding-bottom: var(--Space-48);

  @include for-laptop-and-up {
    padding-bottom: var(--Space-80);
  }
}

.bottomBackground {
  position: absolute;
  width: 100%;
  height: 40vw;
  z-index: 0;
  bottom: 0;
  background: var(--GrayLightHover--opaque);

  @include for-tablet-and-up {
    height: 20vw;
  }
}

.background {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;

  @media (max-width: 475px) {
    top: 45px;
  }

  @include for-phone-only {
    .tabletBackground,
    .desktopBackground,
    .largeDesktopBackground {
      display: none;
    }
  }

  @include for-tablet-only {
    .phoneBackground,
    .desktopBackground,
    .largeDesktopBackground {
      display: none;
    }
  }

  @media (min-width: $laptop-range-start) and (max-width: 1440px) {
    .phoneBackground,
    .tabletBackground,
    .largeDesktopBackground {
      display: none;
    }
  }

  @media (min-width: 1441px) {
    .phoneBackground,
    .tabletBackground,
    .desktopBackground {
      display: none;
    }
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.foreground {
  z-index: 2;
  position: relative;
}

.buffer {
  @include for-phone-only {
    height: var(--Space-56);
    display: block;
  }
  display: none;
}
